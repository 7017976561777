import React from 'react';

import Layout from '../../components/MenuLayout';
import { VirtualVisitsView } from '../../components/VirtualVisits/VirtualVisitsView';
import { VirtualVisitsPatientView } from '../../components/VirtualVisits/VirtualVisitsPatientView';
import { useApplicationInterface } from '../../utils/ApplicationInterfaceUtils';

const { patientApp } = useApplicationInterface();

export default () => (
  <Layout>
    {patientApp ? <VirtualVisitsPatientView /> : <VirtualVisitsView />}
  </Layout>
);
